import * as React from "react"

import LayoutEnglish from "../components/layoutEnglish"
import Seo from "../components/seo"

import downloadAppApple from "../images/download_app_apple.png"
import downloadAppGoogle from "../images/download_app_google.png"
import cityStoriesApp from "../images/citystories_app.jpg"
import qrAppStore from "../images/qr_app_store.png"
import qrPlayStore from "../images/qr_play_store.png"

const DownloadPage = () => (
  <LayoutEnglish>
    <Seo title="City Stories" />
    <div style={{ display: `flex`, justifyContent: `space-around`, paddingTop: `80px`, paddingBottom: `120px` }}>
      <div style={{ width: `65%` }}>
        <h2 style={{ marginBottom: `40px`, borderBottom: `6px dotted black` }}>Your Story</h2>
        <div>
          <h3>Appointment booking Interview</h3>
          If you would like to tell your story about a particular place at studio_bnx, you can book an appointment directly here.<br/>
          <br/>
          <a href="https://form.jotform.com/222291995038362">https://form.jotform.com/222291995038362</a>
          <br/>
        </div>
        <div>
          <h3>Record your story</h3>
          Wenn du deine Geschichte lieber aufnehmen und an uns schicken möchtest, hast du hier die Möglichkeit dazu.<br/>
          <br/>
          <a href="https://form.jotform.com/222293307118047">https://form.jotform.com/222293307118047</a><br/>
          <br/>
        </div>
        <div>
          If you don't want to use Jotform you can also contact us by mail.<br />
          <a href="mailto:mail@citystories-bnx.de">mail@citystories-bnx.de</a><br/>
        </div>
      </div>
      <div style={{ width: `33%` }}>
        <div style={{ maxWidth: `95%` }}>
          <img style={{ maxWidth: `95%` }} src={cityStoriesApp} alt="City Stories App" /><br/>
        </div>
        <div style={{ maxWidth: `95%`, display: `flex`, alignItems: `center` }}>
          <a href="https://apps.apple.com/us/app/citystories-bnx/id1629714118"><img style={{ maxWidth: `90%` }} src={downloadAppApple} alt="City Stories App für iOS herunterladen" /></a>
          <img style={{ maxWidth: `25%` }} src={qrAppStore} alt="City Stories App QR Code iOS" />
        </div>
        <div style={{ maxWidth: `95%`, display: `flex`, alignItems: `center` }}>
          <a href="https://play.google.com/store/apps/details?id=com.Fehime.citystories_bnx"><img style={{ maxWidth: `90%` }} src={downloadAppGoogle} alt="City Stories für Goole Android herunterladen" /></a>
          <img style={{ maxWidth: `25%` }} src={qrPlayStore} alt="City Stories App QR Code Android" />
        </div>
      </div>
    </div>
  </LayoutEnglish>
)

export default DownloadPage
